// VANILLA JS
var navbarOpen = false;
var dropdownOpen = false;
const navLinks = document.querySelector("#nav-links");
const dropdownMenu = document.querySelector("#dropdown-menu");

navLinks.classList.toggle("hidden", !navbarOpen);

function onToggleMenu() {
  navbarOpen = !navbarOpen;
  console.log(navbarOpen);
  navLinks.classList.toggle("hidden", !navbarOpen);
}

function onDropdownMenu() {
  dropdownOpen = !dropdownOpen;
  console.log(navbarOpen);
  dropdownMenu.classList.toggle("hidden", !dropdownOpen);
}
